// Colors
$blue: #1c88bf;
$dark-blue: #364051;
$dark-gray: #333333;
$gray: #666666;
$light-gray: #888888;
$off-white: #f7f7f7;
$red: #e10600;
$very-light-gray: #cacccd;

// Font Size
$font-size-small: 1.4rem;
$font-size-large: 1.8rem;

// Spacing
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$desktop-breakpoint: 45rem;
