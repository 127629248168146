.form {
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: $s-size;
  }
}

.form__input-group {
  display: flex;
  flex-direction: column;
  label {
    font-style: italic;
  }
}

.form__error {
  margin: 0 0 $m-size 0;
  font-style: italic;
}
