.button {
  background: $blue;
  border: none;
  color: white;
  display: inline-block;
  font-size: $font-size-large;
  font-weight: 300;
  line-height: 1;
  padding: $s-size;
  text-decoration: none;
}

.button--login {
  width: 191px;
  height: 46px;
}

.button--login-google {
  @extend .button--login;
  background: url('../images/btn_google_signin_dark.png');
  background-size: contain;
}

.button--login-twitter {
  @extend .button--login;
  background: url('../images/btn_twitter.png');
  background-size: contain;
}

.button--link {
  background: none;
}

.button--secondary {
  background: $red;
}

.button--modal {
  padding: $s-size $l-size;
}
