.text-input {
  border: 1px solid $very-light-gray;
  height: 50px;
  padding: $s-size;
  font-size: $font-size-large;
  font-weight: 300;
}

.select {
  @extend .text-input;
}

.textarea {
  @extend .text-input;
  height: 10rem;
}
