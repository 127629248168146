// NOTE: the order of these styles DO matter

// Will edit everything selected including everything between a range of dates
.CalendarDay__selected_span {
  background: lighten($blue, 10%); //background
  color: white; //text
  border: 1px solid $very-light-gray;
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  background: $blue;
  color: white;
}

// Will edit when hovered over. _span style also has this property
.CalendarDay__selected:hover {
  @extend .CalendarDay__selected_span;
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  @extend .CalendarDay__selected;
}
